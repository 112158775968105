import { Link } from "react-router-dom";
import {
  LoadingListCards,
  MainButton,
  BodyContainer,
  PrimaryHeading,
  HeaderContainer,
  RightMainContainer,
  ListCard,
  ListIconWrapper,
  TemplateNotFound,
} from "commonUI";
import { TagsIcon, AddIcon } from "allIcons";
import { useTags } from "api/siteSettings/Tags";
import { useSearch } from "helpers";
import { useDeleteTag } from "hooks/tags";
import { useUserPermissions } from "hooks/user";
import { t } from "i18next";

export default function Tags({ isSettings }) {
  const { web = null } = useSearch();
  const { data: tags, isLoading } = useTags(isSettings || web ? 0 : 1);

  const deleteTagMutation = useDeleteTag();
  const {
    add_new_items_to_site_template,
    remove_items_from_site_template,
    edit_item_details_in_site_template,
  } = useUserPermissions();

  return (
    <>
      <RightMainContainer>
        <HeaderContainer>
          <PrimaryHeading>Tags</PrimaryHeading>
          {add_new_items_to_site_template ? (
            <Link to={`add${web ? `?web=${web}` : ""}`}>
              <MainButton>
                <AddIcon />
                {t("button.add")}
              </MainButton>
            </Link>
          ) : (
            ""
          )}
        </HeaderContainer>
        <BodyContainer>
          <div className="">
            {isLoading ? (
              <LoadingListCards />
            ) : tags.length === 0 ? (
              <TemplateNotFound
                label="Tag"
                linkLabel="Tag"
                filter={false}
                link={add_new_items_to_site_template ? `add${web ? `?web=${web}` : ""}`: ''}
              />
            ) : (
              tags?.map((iner, index) => {
                return (
                  <ListCard
                    data={iner.tag_title}
                    key={index}
                    index={index}
                    link={
                      edit_item_details_in_site_template
                        ? isSettings
                          ? `/account-settings/tags/${iner.id}`
                          : `/site-settings/tags/${iner.id}${
                              web ? `?web=${web}` : ""
                            }`
                        : ""
                    }
                    editIcon={edit_item_details_in_site_template}
                    deleteIcon={!web || !remove_items_from_site_template}
                    crossIcon={!web && remove_items_from_site_template}
                    showModalonCross
                    strictLink={true}
                    onDelete={() => {
                      deleteTagMutation.mutate(iner.id);
                    }}
                    icon={
                      <ListIconWrapper>
                        <TagsIcon />
                      </ListIconWrapper>
                    }
                    deleteButtonText={web ? "Confirm Delete" : "Confirm"}
                    deleteTitle={web ? "Delete This Tag?" : "Remove This Tag?"}
                    deleteMessage={
                      web
                        ? "You are about to delete this tag from your site. This action cannot be undone! Are you sure you want to do this?"
                        : "You are about to remove this tag from your default site template. Are you sure you want to do this?"
                    }
                  />
                );
              })
            )}
          </div>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}

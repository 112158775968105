import {
  RightMainContainer,
  HeaderContainer,
  PrimaryHeading,
  IconWrapper,
  MainButton,
  InputField,
  BodyContainer,
  TextEditor,
  SecondaryHeading,
  PreviewCard,
  AddComponent,
  PopOver,
  DropDownCard,
  LoadingListCards,
  Loader,
  DeleteButton,
} from "commonUI";
import { BackArrow, SaveIcon } from "allIcons";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  useDeleteNoteTemplate,
  useTemplateNotes,
  useUpdateNoteTemplate,
} from "api/templateLibrary/notes";
import { useNotification } from "notification";
import { useSelector } from "react-redux";
import { getUserSavePermission, isEqual, parse } from "helpers";
import { useTags } from "api/siteSettings/Tags";
import { useAddNoteTemplate } from "api/templateLibrary/notes";
import { useUserPermissions } from "hooks/user";

export default function AddEditNotes() {
  const { data: tags, isLoading: tagsLoading, error: tagsError } = useTags();
  const {
    data: notes,
    isLoading: noteLoading,
    error: noteError,
  } = useTemplateNotes();
  const [inputValues, setInputValues] = useState({
    title: "",
    message: "",
    tags: [],
  });
  const [openTagModal, setOpenTagModal] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const notification = useNotification();
  const { userData } = useSelector((state) => state.user);
  const { add_new_templates, edit_templates, delete_templates } =
    useUserPermissions();

  useEffect(() => {
    if (isEqual(id, "add")) return;
    if (noteLoading) return;
    const note = notes.find((note) => isEqual(note.id, id));
    if (note) {
      setInputValues({
        title: note.title,
        message: note.message,
        tags: parse(note.tags),
      });
    }
  }, [notes, id]);

  const { mutate: addNote, isLoading: addingNote } = useAddNoteTemplate(
    "/template-library/notes/"
  );

  const updateMutation = useUpdateNoteTemplate();

  const { mutate: deleteMutation, isLoading: deleteMutationIsLoading } =
    useDeleteNoteTemplate("/template-library/notes");

  const handleSaveChanges = () => {
    if (inputValues.title === "") {
      notification({
        message: "Title is required",
        type: "error",
      });
      return;
    }
    if (isEqual(id, "add")) {
      addNote({
        ...inputValues,
        requested_from: [
          {
            user_avatar: userData.user_avatar,
            role: userData.rolesData?.[0]?. role_title ?? "Client",
            group: "Admin",
            name: userData.name,
            id: userData.id,
          },
        ],
      });
    }
    if (id !== "add") {
      updateMutation.mutate({
        ...inputValues,
        id,
      });
    }
  };

  return (
    <>
      <RightMainContainer>
        {addingNote || updateMutation.isLoading || deleteMutationIsLoading ? (
          <Loader loader="block" />
        ) : (
          ""
        )}
        <HeaderContainer>
          <IconWrapper>
            <BackArrow onClick={() => navigate("/template-library/notes")} />
            <PrimaryHeading className="clamp-text">
              {isEqual(id.toLowerCase(), "add")
                ? "Add Note"
                : inputValues.title}
            </PrimaryHeading>
          </IconWrapper>
          <div className="flex items-center justify-center gap-14">
            <DeleteButton
              show={id !== "add" && delete_templates}
              onDelete={() => deleteMutation(id)}
              deleteTitle="Delete This Template?"
              deleteButtonText="Confirm Delete"
              deleteMessage="You are about to delete this template from your account. This action cannot be undone! All template content will be permanently removed. Are you sure you want to do this?"
            />
            {getUserSavePermission({
              id,
              addPermission: add_new_templates,
              editPermission: edit_templates,
            }) ? (
              <MainButton onClick={handleSaveChanges}>
                <SaveIcon fill="white" />
                Save Changes
              </MainButton>
            ) : (
              ""
            )}
          </div>
        </HeaderContainer>
        <BodyContainer>
          <InputField
            placeholder="Write Title Here"
            label="Title"
            value={inputValues.title}
            setValue={(value) =>
              setInputValues({
                ...inputValues,
                title: value,
              })
            }
          />
          <TextEditor
            placeholder="Write Description Here"
            label="Description"
            height={150}
            value={inputValues.message}
            setValue={(value) =>
              setInputValues({
                ...inputValues,
                message: value,
              })
            }
            reRender={id === "add" ? false : inputValues.message ? true : false}
          />
          <div className="mt-2">
            <SecondaryHeading>Tags</SecondaryHeading>
            {inputValues.tags?.length > 0 ? (
              <div className="flex items-center gap-5">
                {inputValues.tags?.map((tag, index) => (
                  <PreviewCard
                    color={tag.color}
                    title={tag.text}
                    icon={true}
                    className="m-0"
                    onCross={() =>
                      setInputValues((prev) => ({
                        ...prev,
                        tags: prev.tags.filter((_, i) => i !== index),
                      }))
                    }
                  />
                ))}
              </div>
            ) : (
              ""
            )}

            <AddComponent
              title="Add Tag"
              value={openTagModal}
              onClick={() => setOpenTagModal(true)}
            >
              <PopOver
                open={openTagModal}
                onClose={() => setOpenTagModal(false)}
                style={{
                  bottom: "calc(100% + 15px)",
                  top: "unset",
                  right: "unset",
                  left: "unset",
                  width: "270px",
                }}
                toolTip={{
                  horizontal: "center",
                  vertical: "bottom",
                }}
              >
                {tagsError ? (
                  <p className="body-text p-3">Can't load Tags right now.</p>
                ) : tagsLoading ? (
                  <div className="p-3">
                    <LoadingListCards rows={3} listClass={"h-8"} />
                  </div>
                ) : (
                  <DropDownCard
                    title="Tags"
                    listStyle={true}
                    className="relative-important"
                    selectedValues={inputValues.tags.map((item) => item.id)}
                    list={tags.map((ele) => ({
                      ...ele,
                      index: ele.id,
                      text: ele.tag_title,
                    }))}
                    onSelect={(item) => {
                      if (
                        inputValues.tags.find((i) => isEqual(i.id, item.id))
                      ) {
                        return;
                      }
                      setInputValues((pre) => ({
                        ...pre,
                        tags: [
                          ...pre.tags,
                          {
                            id: item.id,
                            text: item.tag_title,
                            color: item.color,
                          },
                        ],
                      }));
                    }}
                  />
                )}
              </PopOver>
            </AddComponent>
          </div>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}

import { Children, useDeferredValue, useEffect, useState } from "react";
import { AddIcon, FilesIcon } from "allIcons";
import {
  RightMainContainer,
  HeaderContainer,
  PrimaryHeading,
  MainButton,
  ListCard,
  ListIconWrapper,
  InputField,
  BodyContainer,
  LoadingListCards,
  TemplateNotFound,
  Loader,
  FiltersList,
} from "commonUI";
import {
  useAddContentRequest,
  useContentRequests,
  useDeleteContentRequest,
} from "api/templateLibrary/contentRequests";
import { Link } from "react-router-dom";
import { parse, search } from "helpers";
import {
  libraryItemFiltering,
  libraryItemSorting,
} from "filterAndSort/templateLibrary";
import { useTags } from "api/siteSettings/Tags";
import { useUserPermissions } from "hooks/user";
import { t } from "i18next";

export default function ContentRequestTemplates() {
  const { data: contentRequests, isLoading } = useContentRequests();
  const { mutate: deleteContentRequest, isLoading: isDeleting } =
    useDeleteContentRequest();
  const { mutate: duplicateContentRequest, isLoading: isDuplicating } =
    useAddContentRequest(false);
  const { data: tags, isLoading: tagsLoading, error: tagsError } = useTags();
  const { add_new_templates, edit_templates, delete_templates } =
    useUserPermissions();

  const [searchText, setSearchText] = useState("");
  const defferedSearch = useDeferredValue(searchText);
  const [searchResult, setSearchResult] = useState([]);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    setSearchResult(
      contentRequests.filter((item) => search(item.title, defferedSearch))
    );
  }, [defferedSearch, contentRequests]);

  const applySorting = (order, orderBy) => {
    setSearchResult((prevSearchResult) =>
      libraryItemSorting(order, orderBy, prevSearchResult)
    );
  };

  const applyFilter = ({
    selectedFilter,
    selectedTags,
    selectedLibrarySource,
    ...data
  }) => {
    setFilterData({
      ...data,
      selectedTags,
      selectedLibrarySource,
      selectedFilter,
    });
    setSearchResult(
      libraryItemFiltering(
        selectedFilter,
        selectedTags,
        selectedLibrarySource,
        contentRequests
      )
    );
  };

  return (
    <>
      <RightMainContainer>
        {isDeleting || isDuplicating ? <Loader loader="block" /> : ""}
        <HeaderContainer>
          <PrimaryHeading>Content Requests</PrimaryHeading>
          {add_new_templates ? (
            <Link to="add">
              <MainButton>
                <AddIcon />
                {t("button.add")}
              </MainButton>
            </Link>
          ) : (
            ""
          )}
        </HeaderContainer>
        <BodyContainer>
          <InputField
            placeholder={t("input.search.placeholder")}
            searchIcon={true}
            filter={true}
            value={searchText}
            setValue={(val) => setSearchText(val)}
            tags={tags ?? []}
            onApplyFilter={applyFilter}
            applySorting={applySorting}
            filterData={filterData}
          />
          <FiltersList filterData={filterData} updateFilter={applyFilter} />
          {isLoading ? (
            <LoadingListCards />
          ) : searchResult.length === 0 ? (
            <TemplateNotFound
              label="Content Request"
              linkLabel="Content Request"
              link={add_new_templates ? "add" : ""}
            />
          ) : (
            Children.toArray(
              searchResult.map((ele, index) => {
                return (
                  <ListCard
                    data={ele.title}
                    duplicate={add_new_templates}
                    editIcon={edit_templates}
                    link={
                      edit_templates
                        ? `/template-library/content-request-templates/${ele.id}`
                        : ""
                    }
                    strictLink={true}
                    onDuplicate={() => {
                      duplicateContentRequest({
                        title: `Copy of ${ele.title}`,
                        message: ele.message,
                        contentitems: parse(ele.contentitems),
                        tags: parse(ele.tags),
                        phase_id: ele.phase_id,
                        phase_due_date_auto: ele.phase_due_date_auto,
                      });
                    }}
                    deleteIcon={!delete_templates}
                    onDelete={() => {
                      deleteContentRequest(ele.id);
                    }}
                    icon={
                      <ListIconWrapper>
                        <FilesIcon />
                      </ListIconWrapper>
                    }
                    deleteTitle="Delete This Template?"
                    deleteButtonText="Confirm Delete"
                    deleteMessage="You are about to delete this template. It will be permanently removed from your account library. Are you sure you want to do this?"
                  />
                );
              })
            )
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}

import { Link, useNavigate } from "react-router-dom";
import {
  SiteIcon,
  UserIcon,
  CloudIcon,
  LogoutIcon,
  BillingIcon,
  SupportIcon,
  FormFieldIcon,
  SettingIcon,
  SingleUserIcon,
} from "allIcons";
import "./header.css";
import { useState } from "react";
import {
  IconContainer,
  Loader,
  LoadingCard,
  PopOver,
  SecondaryHeading,
  UserAvatar,
} from "commonUI";
import { isEqual, usePathname } from "helpers";
import { useUserDetails, useUserLogout, useUserPermissions } from "hooks/user";
import useUserPlan from "hooks/plan";
import { t } from "i18next";

export default function Header() {
  const [showDrop, setShowDrop] = useState(false);
  const [module] = usePathname();
  const { view_templates, view_sites } = useUserPermissions();
  const { data: wpslPlan = {}, isLoading: loadingPlans } = useUserPlan();
  const isLibraryTemplateAllowed = isEqual(wpslPlan?.temp_access, 1);
  const navigate = useNavigate();

  const { isLoading, data: userDetails, error } = useUserDetails();

  const mutation = useUserLogout();

  return (
    <>
      <Loader show={mutation.isLoading} />
      <Loader className="bg-white" show={isLoading} />
      <div className="main-header">
        <Link to="/" className="flex" aria-label="WP SiteLauncher">
          <img
            src={require("assets/header-logo.svg").default}
            width="220px"
            height="35px"
            alt="WP SiteLauncher Logo"
          />
        </Link>
        <div className="header-left-container">
          {view_sites ? (
            <Link
              to={"sites"}
              title={t("header.menu.sites")}
              className="cursor-pointer"
            >
              <div
                className={`header-icon-container ${
                  ["sites", "", "site-settings"].includes(module)
                    ? "bg-theme-blue"
                    : ""
                }`}
              >
                <SiteIcon />
              </div>
            </Link>
          ) : (
            ""
          )}
          <Link
            to={"users"}
            title={t("header.menu.users")}
            className="cursor-pointer"
          >
            <div
              className={`header-icon-container ${
                module === "users" ? "bg-theme-blue" : ""
              }`}
            >
              <UserIcon />
            </div>
          </Link>
          {view_templates && isLibraryTemplateAllowed && !loadingPlans ? (
            <Link
              to={"/template-library"}
              title={t("header.menu.templateLibrary")}
              className="cursor-pointer"
            >
              <div
                className={`header-icon-container
            ${module === "template-library" ? "bg-theme-blue" : ""}
            `}
              >
                <CloudIcon />
              </div>
            </Link>
          ) : (
            ""
          )}
          <Link
            to={"account-settings"}
            title={t("header.menu.accountSettings")}
            className="cursor-pointer"
          >
            <div
              className={`header-icon-container ${
                module === "account-settings" ? "bg-theme-blue" : ""
              }`}
            >
              <SettingIcon />
            </div>
          </Link>
          <div className="relative">
            <div
              className={`${showDrop ? "dropdown-click-block" : "hidden"}`}
            />
            <div
              className="flex items-center cursor-pointer ml-1 relative"
              onClick={() => {
                setShowDrop((pre) => !pre);
              }}
            >
              <div className="header-icon-container">
                <UserAvatar user={userDetails} />
              </div>
              <div className="header-user-container">
                {isLoading ? (
                  <LoadingCard className="h-2 w-14" />
                ) : (
                  <SecondaryHeading className="text-white font-bold ">
                    {userDetails?.user_name ?? userDetails?.name}&nbsp;
                    {userDetails?.last_name}
                  </SecondaryHeading>
                )}
              </div>
              <FormFieldIcon />
              <PopOver
                open={showDrop}
                onClose={() => {
                  setShowDrop(() => false);
                }}
                toolTip={{ right: "20px", vertical: "top" }}
                style={{ top: "calc(100% + 7px)", right: "20px" }}
              >
                <div className=" header-user-dropdown">
                  <div
                    className="header-dropdown-content cursor-pointer"
                    onClick={() => {
                      navigate("/users/team-members/" + userDetails.id);
                      setShowDrop(() => false);
                    }}
                  >
                    <IconContainer>
                      <SingleUserIcon />
                    </IconContainer>
                    <span className="body-text m-0">
                      {t("header.menu.editProfile")}
                    </span>
                  </div>
                  <a
                    href="https://wpsitelauncher.com/help"
                    target="_blank"
                    rel="noreferrer"
                    className="header-dropdown-content"
                  >
                    <IconContainer>
                      <SupportIcon />
                    </IconContainer>
                    <span className="body-text m-0">
                      {t("header.menu.helpCenterAndSupport")}
                    </span>
                  </a>
                  <a
                    className="header-dropdown-content"
                    href="https://wpsitelauncher.com/customer-dashboard/?action=index&model=subscription"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconContainer>
                      <BillingIcon />
                    </IconContainer>
                    <span className="body-text m-0">
                      {t("header.menu.managePlanAndBilling")}
                    </span>
                  </a>
                  <div
                    className="header-dropdown-content cursor-pointer"
                    onClick={() => mutation.mutate()}
                  >
                    <IconContainer>
                      <LogoutIcon />
                    </IconContainer>
                    <span className="body-text m-0">
                      {t("header.menu.logout")}
                    </span>
                  </div>
                </div>
              </PopOver>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

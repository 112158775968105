import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Loader,
  MainButton,
  LoadingCard,
  PreviewCard,
  InputField,
  IconWrapper,
  BodyContainer,
  PrimaryHeading,
  HeaderContainer,
  ColorPicker,
  RightMainContainer,
  DeleteButton,
} from "commonUI";
import { SaveIcon, BackArrow } from "allIcons";
import { useTags } from "api/siteSettings/Tags";
import { getUserSavePermission, isEqual, titleCase, useSearch } from "helpers";
import { useNotification } from "notification";
import { useAddTag, useDeleteTag, useUpdateTag } from "hooks/tags";
import { useUserPermissions } from "hooks/user";

export default function TagSetting({ isSettings }) {
  const { web = null } = useSearch();
  const navigate = useNavigate();
  const { isLoading, error, data: tags } = useTags(isSettings || web ? 0 : 1);
  let { id } = useParams();
  const [value, setValue] = useState("#0066ff");
  const [inputValue, setInputValue] = useState("");
  const notification = useNotification();
  const {
    add_new_items_to_site_template,
    remove_items_from_site_template,
    edit_item_details_in_site_template,
  } = useUserPermissions();

  useEffect(() => {
    if (isLoading) return;
    if (id !== "add" && tags.length) {
      let tag = tags.filter((element) => isEqual(element.id, id))[0] ?? {};
      setInputValue(titleCase(tag?.tag_title ?? ""));
      setValue(tag?.color ?? "#0066ff");
    }
  }, [isLoading, id, tags]);

  const updateMutation = useUpdateTag();

  const addMutation = useAddTag(isSettings);
  const deleteTagMutation = useDeleteTag(
    !isSettings
      ? `/site-settings/tags${web ? `?web=${web}` : ""}`
      : "/account-settings/tags"
  );

  function handleSaveChanges() {
    if (!inputValue || !inputValue.trim() || !value || !value.trim()) {
      notification({
        message: `Please enter ${
          !inputValue || !inputValue.trim() ? "title" : "color"
        }`,
        type: "error",
      });
      return;
    }
    if (id !== "add") {
      updateMutation.mutate({
        tag_title: inputValue,
        color: value,
        id,
        website_id: web,
      });
      return;
    }
    addMutation.mutate({
      tag_title: inputValue,
      color: value,
      website_id: web,
      system_status: isSettings || web ? 0 : 1
    });
  }
  return (
    <>
      <RightMainContainer>
        {(addMutation.isLoading ||
          updateMutation.isLoading ||
          deleteTagMutation.isLoading) && <Loader loader="block" />}
        <HeaderContainer>
          <IconWrapper>
            <BackArrow
              onClick={() =>
                navigate(
                  !isSettings
                    ? `/site-settings/tags${web ? `?web=${web}` : ""}`
                    : "/account-settings/tags"
                )
              }
            />
            {isLoading ? (
              <LoadingCard className="w-40 h-6" />
            ) : (
              <PrimaryHeading>{isEqual(id, 'add') ? "Add Tag" :  inputValue}</PrimaryHeading>
            )}
          </IconWrapper>
          <div className="flex items-center gap-14">
            <DeleteButton
              show={id !== "add" && remove_items_from_site_template && web}
              deleteMessage="You are about to delete this tag from your site. This action cannot be undone! Are you sure you want to do this?"
              deleteTitle="Delete This Tag?"
              deleteButtonText="Confirm Delete"
              onDelete={() => deleteTagMutation.mutate(id)}
            />
            {getUserSavePermission({
              id,
              addPermission: add_new_items_to_site_template,
              editPermission: edit_item_details_in_site_template,
            }) ? (
              <MainButton onClick={handleSaveChanges}>
                <SaveIcon />
                Save changes
              </MainButton>
            ) : (
              ""
            )}
          </div>
        </HeaderContainer>
        <BodyContainer>
          <div>
            <InputField
              label="Title"
              placeholder="Write Title Here"
              value={inputValue}
              setValue={setInputValue}
              isLoading={isLoading}
            />
          </div>
          <div>
            <PreviewCard
              title={inputValue}
              color={(value?.[0] ?? "") == "#" ? value : "#" + value ?? ""}
              isLoading={isLoading}
              icon={true}
            />
          </div>
          <div>
            <InputField
              label="Color"
              placeholder="Write Color Here"
              value={(value?.[0] ?? "") == "#" ? value : "#" + value ?? ""}
              setValue={setValue}
              isLoading={isLoading}
            />
          </div>
          <div>
            <ColorPicker
              value={(value?.[0] ?? "") == "#" ? value : "#" + value ?? ""}
              setValue={setValue}
            />
          </div>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
